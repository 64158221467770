import { PromosList } from "@royalcanin-be-partner-portal/ui-kit";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Layout } from "../components/Layout";
import { CheckPermission } from "../components/CheckPermission";
import { usePromos } from "../lib/hooks/usePromos";
import { withPage } from "../lib/withPage";

const Promos = () => {
  const { items, loading } = usePromos();
  return (
    <PromosList
      loading={loading}
      promos={
        items.map(item => ({
          ...item,
          image: item.banner || "",
          url: item.url || "",
          subscribeLabel: (
            <FormattedMessage
              id="website.promos.subscribeLabel"
              defaultMessage="Je participe"
            />
          ),
        })) || []
      }
    />
  );
};

const PromosPage = () => (
  <Layout>
    <CheckPermission permissions={"promos"} redirect>
      <Promos />
    </CheckPermission>
  </Layout>
);

export default withPage(PromosPage);
