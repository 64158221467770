import { useUserToken } from "@igloo-be-omnipartners/hooks";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useLocale } from "../locale";
import { Locale } from "../../../../../__generated__/globalTypes";
import { Promos, PromosVariables } from "./__generated__/Promos";

const PromosQuery = gql`
  query Promos($token: String!, $locale: Locale!) {
    promos(token: $token, locale: $locale) {
      result {
        name
        intro
        banner
        url
      }
      error {
        message
        code
      }
    }
  }
`;

export const usePromos = () => {
  const token = useUserToken();
  const locale = useLocale();
  const { data, ...res } = useQuery<Promos, PromosVariables>(PromosQuery, {
    skip: !token,
    variables: {
      token,
      locale: Locale[locale],
    },
  });

  return {
    ...res,
    data,
    items: data?.promos.result || [],
  };
};
